.input-group {
  position: relative;
  margin: 27px 0px;
}

input, textarea {
  font-size: 18px;
  padding: 12px 14px;
  display: block;
  width: 100%;
  border: 1px solid #d0d0d0;
  border-radius: 14px;
  background-color: #fbfbfb;
  box-sizing: border-box;
  transition: all 0.2s ease;
}

input:focus, textarea:focus {
  outline: none;
  border-color: purple;
  background-color: #fff;
  box-shadow: 0 0 0 3px rgba(128, 0, 128, 0.1);
}

label {
  color: #999;
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 12px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  background-color: transparent;
  padding: 0 5px;
}

textarea:focus ~ label,
textarea:valid ~ label,
input:focus ~ label,
input:valid ~ label {
  top: -10px;
  font-size: 14px;
  color: purple;
  background-color: #fff;
  border-radius: 4px;
}

.bar {
  position: relative;
  display:block;
  width: 100%;
}

.bar:before,
.bar:after {
  content: '';
  height: 2px;
  width: 0;
  bottom: 0px;
  position: absolute;
  background: purple;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.bar:before {
  left: 50%;
}

.bar:after {
  right: 50%;
}

textarea:focus ~ .bar:before,
textarea:focus ~ .bar:after,
input:focus ~ .bar:before,
input:focus ~ .bar:after {
  width: 47%;
}

.highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

textarea:focus ~ .highlight,
input:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

/* animations */
@-webkit-keyframes inputHighlighter {
  from { background: #4285f4; }
  to   { width: 0; background: transparent; }
}
@-moz-keyframes inputHighlighter {
  from { background: #4285f4; }
  to   { width: 0; background: transparent; }
}
@keyframes inputHighlighter {
  from { background: #4285f4; }
  to   { width: 0; background: transparent; }
}

/* Error message styling */
.error-message {
  color: #d32f2f;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  animation: fadeIn 0.3s ease-in-out;
}

/* Error state styling */
input.error, 
textarea.error {
  border: 2px solid #d32f2f;
  background-color: #fff9f9;
}

input:focus.error ~ .bar:before,
input:focus.error ~ .bar:after,
textarea:focus.error ~ .bar:before,
textarea:focus.error ~ .bar:after {
  background: #d32f2f;
}

input[aria-invalid="true"],
textarea[aria-invalid="true"] {
  border: 2px solid #d32f2f;
  background-color: #fff9f9;
}

input[aria-invalid="true"] ~ label,
textarea[aria-invalid="true"] ~ label {
  color: #d32f2f;
}

input[aria-invalid="true"]:focus,
textarea[aria-invalid="true"]:focus {
  box-shadow: 0 0 0 3px rgba(211, 47, 47, 0.1);
}

/* Animation for error appearance */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Dropdown field styling */
.dropdown-group {
  position: relative;
}

.dropdown-label {
  color: purple;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
  display: block;
}

/* Enhanced select styles */
select {
  font-size: 18px;
  padding: 12px 14px;
  display: block;
  width: 100%;
  border: 1px solid #d0d0d0;
  border-radius: 14px;
  background-color: #fbfbfb;
  box-sizing: border-box;
  transition: all 0.2s ease;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23666' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 16px;
  cursor: pointer;
}

select:hover {
  border-color: #aaa;
  background-color: #f5f5f5;
}

select:focus {
  outline: none;
  border-color: purple;
  background-color: #fff;
  box-shadow: 0 0 0 3px rgba(128, 0, 128, 0.1);
}

select[aria-invalid="true"] {
  border: 2px solid #d32f2f;
  background-color: #fff9f9;
}

/* Option styling */
option {
  padding: 8px;
  font-size: 16px;
}